import Axios from "axios";

// export const API_HOST = "https://localhost:44312/api";
// export const API_HOST = "https://cipherdatasolutions-api-stage.azurewebsites.net/api";
export const API_HOST = process.env.REACT_APP_API_HOST;

export const json = () => Axios.create({
  baseURL: API_HOST,
  headers: {
    // 'Authorization': "Bearer " + accessToken,
    'Content-Type': 'application/json',
    'accept': 'application/json',
  },
  timeout: 100000,
});  


export const zip = () =>  Axios.create({
  baseURL: API_HOST,
  headers: {
    'Accept': 'application/zip'
  },
  responseType: 'blob',
  timeout: 100000,
});  

export default json;
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import RouteWithLayout from './components/RouteWithLayout';
import PublicLayout from './layouts/public';
import MainLayout from './layouts/Main';
import Landing from './pages/Landing';
import Home from './pages/Home';
import Products from './pages/Products';
import Services from './pages/Services';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import ContactConfirm from './pages/ContactConfirm';
import DownloadConfirm from './pages/DownloadConfirm';
import Privacy from './pages/Privacy';
import ThirdParty from './pages/ThirdParty';
import PageNotFound from './pages/PageNotFound';
import PurviewSetupScript from './pages/PurviewSetupScript';
import Documentation from './pages/PurviewConnectors/Documentation';
import SSRS from './pages/PurviewConnectors/SSRS';
import PowerBI from './pages/PurviewConnectors/PowerBI';
import SSIS from './pages/PurviewConnectors/SSIS';
import SSAS from './pages/PurviewConnectors/SSAS';
// import DownloadInstaller from './pages/PurviewConnectors/Download';


import { useTracking } from './until/useTracking'



export const Router = () => {
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID)
  return null
};

export default () => (
<BrowserRouter>
      <Router />
      <Switch>
        <RouteWithLayout exact path="/" component={Home} layout={MainLayout} />
        <RouteWithLayout exact path="/products" component={Products} layout={MainLayout} />
        <RouteWithLayout exact path="/services" component={Services} layout={MainLayout} />
        <RouteWithLayout exact path="/about" component={About} layout={MainLayout} />
        <RouteWithLayout exact path="/contact-us" component={ContactUs} layout={MainLayout} />
        <RouteWithLayout exact path="/landing" component={Landing} layout={PublicLayout} />
        <RouteWithLayout exact path="/contact-confirm" component={ContactConfirm} layout={PublicLayout} />
        <RouteWithLayout exact path="/download-confirm" component={DownloadConfirm} layout={PublicLayout} />
        <RouteWithLayout exact path="/privacy" component={Privacy} layout={PublicLayout} />
        <RouteWithLayout exact path="/third-party" component={ThirdParty} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-setup-script" component={PurviewSetupScript} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors" component={Documentation} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors-documentation" component={Documentation} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors-ssrs" component={SSRS} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors-powerbi-report-server" component={PowerBI} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors-ssis" component={SSIS} layout={PublicLayout} />
        <RouteWithLayout exact path="/purview-connectors-ssas" component={SSAS} layout={PublicLayout} />
        {/* <RouteWithLayout exact path="/purview-connectors-download" component={DownloadInstaller} layout={PublicLayout} /> */}
        <RouteWithLayout path="*" component={PageNotFound} layout={PublicLayout} />
      </Switch>
    </BrowserRouter>
);

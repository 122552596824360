import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import ProductCarousel from '../../../components/ProductCarousel'

export default function SSIS() {
  const dispatch = useDispatch();
  const history = useHistory();

  const MarketplaceEntry = "https://tinyurl.com/53dn2yae";

  return (
    <div className="general-page">
      <section className="main-content container">
        <section >
          <div >
            <div className="home-section-title">
            COMING SOON - Purview Connectors - SSIS
            </div>
            <div className="row w-100">
              <div className="col-lg-3" />
              <div className="col ">
                <ProductCarousel />
              </div>
            </div>
            <div className="row justify-content-between pt-3">
              <div className="col justify-content-center">
                <div className="service-item-list-wrapper">
                  {/* <ProductCarousel/> */}
                  <ul class="nav nav-pills justify-content-center">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="pill" href="#overview-tab">Overview</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="pill" href="#documentation-tab">Documentation</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="pill" href="#licensing-tab">Licensing</a>
                    </li>
                  </ul>

                  <div>
                    <div class="tab-content pt-3 text-left" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="overview-tab" role="tabpanel" aria-labelledby="pills-home-tab">
                        <p>
                          Purview Connectors allows any data governance professional to connect to their on-premise data sources and integrate with their existing Azure Purview account.  It extends the functionality of your Azure Purview account by allowing you to include some of your most common data sources.
                        </p>
                        <p>
                          Purview Connectors works as an on-premise Windows service, which collects all metadata and loads it as assets to your Azure Purview account.
                        </p>
                      </div>
                      <div class="tab-pane fade" id="documentation-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <p>
                          To view the documentation and FAQ's, <Link to="/purview-connectors-documentation">visit this page.</Link>
                        </p>
                      </div>
                      <div class="tab-pane fade" id="licensing-tab" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <p>
                          Purview Connectors is licensed per Connector type.  There are four types of connectors available:
                        </p>
                        <ul>
                          <li>SQL Server Reporting Services</li>
                          <li>
                            Power BI Report Server
                          </li>
                          <li>
                            SQL Server Analysis Services
                          </li>
                          <li>
                            SQL Server Integration Services
                          </li>
                        </ul>
                        <p>
                          For more information on pricing, view the entry in the <a target="_blank" href={MarketplaceEntry}>Azure Marketplace.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-2">
                <a target="_blank" href={MarketplaceEntry}>
                  <button className="btn btn-red" >Buy Now</button>
                </a>

              </div>
            </div>
            <div className="service-item-list-wrapper">
            </div>
          </div>
        </section>
      </section>
    </div>
  )
}
import React from 'react';

export default function Privacy() {
  return (
    <div className="main privacy-page">
      <h4>PRIVACY POLICY</h4>

      <p>This Customer Privacy Policy of C. Goll Enterprises, LLC, a Texas limited liability company d/b/a &ldquo;Cipher Data Solutions&rdquo; (<strong>&ldquo;Company&rdquo;</strong>) covers the collection, use, and disclosure of personal information that may be collected by the Company anytime you interact with the Company, such as when you visit our web site, when you purchase the Company's products and services online or in one of our stores or offices, or when you call our sales or support associates. Please take a moment to carefully read the following to learn more about our information practices, including what type of information is gathered, how the information is used and for what purposes, to whom we disclose the information, and how we safeguard your personal information. Your privacy is a priority at the Company, and we go to great lengths to protect it.</p>

      <h5>1. Reasons the Company Collects Personal Information</h5>

      <p>We collect your personal information because it helps us deliver a superior level of customer service. It enables us to give you convenient access to our products and services and focus on categories of greatest interest to you. In addition, your personal information helps us keep you posted on the latest product announcements, special offers, and events that you might like to hear about.</p>

      <h5>2. Information the Company Collects and How the Information May be Used</h5>

      <p>There are a number of situations in which your personal information may help us give you better products. For example:</p>

      <p>We may ask for your personal information when you're discussing a service issue on the phone with an associate, participating in an online survey, registering your products, or purchasing a product.</p>

      <p>When you interact with the Company, we may collect personal information relevant to the situation, such as your name, mailing address, phone number, email address, and contact preferences; your credit card information and information about the Company products you own, such as their serial numbers and date of purchase; and information relating to a support or service issue.</p>

      <p>We also collect information for market research purposes &mdash; such as your occupation and where you use your Company products&mdash;to gain a better understanding of our customers and thus provide more valuable service.</p>

      <p>We collect information regarding customer activities on our web sites. This helps us to determine how best to provide useful information to customers and to understand which parts of our web sites, products, and Internet services are of most interest to them.</p>

      <p>We may use personal information to provide products that you have requested as well as for auditing, research, and analysis to improve our products.</p>

      <p>This information may be shared with third parties or in special circumstances as described elsewhere in this policy.</p>

      <h5>3. Your Online Company ID and Related Information</h5>

      <p>Our web site allows you to create an &ldquo;Cipher ID&rdquo; based on your personal information. This convenient service saves you time and allows for easier use of our web services. In order to create your Cipher ID you will need to create a personal profile by providing your name, phone number, email address, and in some cases your mailing address or a credit card number. The system saves the information in your personal profile and assigns you your own unique personal Cipher ID which you can use when performing various activities relating to our web site and the products and services you purchase from the Company.</p>

      <h5>5. When the Company Discloses Your Information</h5>

      <p>The Company takes your privacy very seriously. The Company does not sell or rent your contact information to other marketers.</p>

      <p>To help us provide superior service, your personal information may be shared with other entities that have a collaborative relationship with the Company, such as professionals, subcontractors, or resellers, who will take steps to safeguard it in accordance with our privacy policy.</p>

      <p>There are also times when it may be advantageous for the Company to make certain personal information about you available to companies that the Company has a strategic relationship with or that perform work for the Company to provide products and services to you on our behalf. These companies may help us process information, extend credit, fulfill customer orders, deliver products to you, manage and enhance customer data, provide customer service, assess your interest in our products and services, or conduct customer research or satisfaction surveys. In such situation these companies are prohibited from using your personal information for any unauthorized purposes and are also obligated to protect your information in accordance with the Company's policies, except if we inform you otherwise at the time of collection. Without such information being made available, it would be difficult for you to purchase products, have products delivered to you, receive customer service, provide us feedback to improve our products and services, or access certain services, offers, and content on our web site.</p>

      <p>The Company may take the personal information we receive from individuals responding to our customer surveys and combine (or aggregate) it with the responses of other customers we may have, to create broader, generic responses to the survey questions (such as gender, age, residence, hobbies, education, employment, industry sector, or other demographic information). The Company then uses the aggregated information to improve the quality of its services to you, and to develop new services and products. This aggregated non-personally identifying information may be shared with third parties.</p>

      <p>We also reserve the right to disclose your personal information for any reason if, in our sole discretion, we believe that it is reasonable to do so, including to credit agencies, collection agencies, merchant database agencies, law enforcement, litigation, or to satisfy laws, such as the Electronic Communications Privacy Act, the Child Online Privacy Act, regulations, or governmental or legal requests for such information. We may also disclose personal information that is necessary to identify, contact, or bring legal action against someone who may be violating our contracts, policies, and procedures. Finally, we may also disclose information about you if we determine that for national security, law enforcement, or other issues of public importance, disclosure is necessary.</p>

      <p>If the Company undergoes a merger or acquisition, it may transfer your information to the new company owner, or the new owner of the Company&rsquo;s assets. At such time, you will be provided notice, and your information will become subject to a different privacy policy.</p>

      <h5>6. How the Company Protects Your Personal Information</h5>

      <p>The Company takes precautions&mdash;including administrative, technical, and physical measures&mdash;to safeguard your personal information against loss, theft, and misuse, as well as unauthorized access, disclosure, alteration, and destruction.</p>

      <p>Our web sites use Secure Sockets Layer (SSL) encryption on all web pages where personal information is required and to make online purchases you must use an SSL-enabled browser in order to protect the confidentiality of your personal and credit card information while it's transmitted over the Internet. You can help us by also taking precautions to protect your personal data when you are on the Internet. Change your passwords often using a combination of letters and numbers, and make sure you use a secure web browser.</p>

      <p>The security of your information is important to the Company, but no method of transmission over the Internet or method of electronic storage is 100% secure. While the Company strives to use commercially acceptable means to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party &ldquo;hackers&rdquo; from illegally obtaining your personal information.</p>

      <h5>7. Integrity of Your Personal Information</h5>

      <p>The Company has safeguards in place to keep your personal information accurate, complete, and up to date for the purposes for which it is used. You always have the right to access and correct the personal information you have provided and can help us ensure that your contact information and preferences are accurate, complete, and up to date by reaching out to us at info@cipherdatasolutions.com. In addition, you can request a copy of your personal information, your product registration history, and your interactions with our sales and support agents by contacting us at the email address below.</p>

      <h5>8. Privacy of Children</h5>

      <p>Protecting children's privacy is especially important to us. The Company recognizes that parents, guardians, or other adults often purchase our products for family use, including use by minors. We do not knowingly collect personal information from children under 13 for marketing purposes, but because some information is collected electronically, it can appear to be the personal information of the Company purchaser of the product, and will be treated as such by this privacy policy. If a child under 13 submits personal information to the Company and we learn that that personal information is the information of a child under 13, we will attempt to delete the information as soon as possible. It is our policy to comply with the Children's Online Privacy Protection Act of 1998 and all other applicable laws. Therefore, we restrict our web site to persons 18 years or older.</p>

      <p>YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THE COMPANY'S WEBSITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS THE COMPANY'S WEB SITE FOR ANY REASON. DUE TO THE AGE RESTRICTIONS FOR USE OF THAT WEBSITE, NO INFORMATION OBTAINED BY THAT WEBSITE FALLS WITHIN THE CHILDREN'S ONLINE PRIVACY PROTECTION ACT OF 1998 AND IS NOT MONITORED AS DOING SO.</p>

      <h5>9. Cookies and Other Technologies</h5>

      <p>As is standard practice on many corporate web sites, our web site uses &ldquo;cookies&rdquo; and other technologies to help us understand which parts of our web sites are the most popular, where our visitors are going, and how much time they spend there. We also use cookies and other technologies to make sure that our online advertising is bringing customers to our products and services. We use cookies and other technologies to study traffic patterns on our web site, to make it even more rewarding as well as to study the effectiveness of our customer communications. And we use cookies to customize your experience and provide greater convenience each time you interact with us.</p>

      <p>If, however, you prefer not to enable cookies, please go to https://www.wikihow.com/Disable-Cookies, which explains step by step how you can disable cookies. Please note that certain features of our web site will not be available once cookies are disabled.</p>

      <p>As is true of most web sites, we gather certain information automatically and store it in log files. This information includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information, which does not identify individual users, to analyze trends, to administer the site, to track users' movements around the site and to gather demographic information about our user base as a whole. The Company will not use the information collected to market directly to that person.</p>

      <p>In some of our email messages we use a &ldquo;click-through URL&rdquo; linked to content on our web site. When customers click one of these URLs, they pass through our web server before arriving at the destination web page. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our customer communications. If you prefer not to be tracked simply avoid clicking text or graphic links in the email.</p>

      <p>In addition we use pixel tags&mdash;tiny graphic images&mdash;to tell us what parts of our web site customers have visited or to measure the effectiveness of searches customers perform on our site. Pixel tags also enable us to send email messages in a format customers can read. And they tell us whether emails have been opened to ensure that we're sending only messages that are of interest to our customers. We may use this information to reduce or eliminate messages sent to a customer. We store all of this information in secure databases.</p>

      <h5>10. Commitment to Your Privacy</h5>

      <p>As we said, the Company takes protecting your privacy very seriously. To make sure your personal information is secure, we communicate these guidelines to our employees and strictly enforce privacy safeguards within the company. In addition, the Company supports industry initiatives to preserve privacy rights on the Internet and in all aspects of electronic commerce.</p>

      <h5> 11. International Users</h5>

      <p>The Company abides by the safe harbor framework set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information collected from the European Union.</p>

      <h5>12. Links to Sites of Other Companies</h5>

      <p>Our web site has links to the sites of other companies. We are not responsible for their privacy practices. We encourage you to learn about the privacy policies of those companies.</p>

      <h5>13. Further Questions Regarding Privacy</h5>

      <p>If you have questions or concerns about the Company's Customer Privacy Policy or data processing, please use the email address to contact us.</p>

      <p>If you do not want the Company to keep you up to date with our news and the latest information on products and services click (need website URL) and update your personal contact information and preferences. Naturally, if you notify us that you do not want us to use your information for a particular purpose, we will not do so.</p>

      <h5>14. Effective Date; Updates to Privacy Policy</h5>

      <p>The terms and conditions in this policy statement came into effect on April 5, 2021. The Company reserves the right to revise, amend, or modify this policy at any time and in any manner. When we change the policy in a material way a notice will be posted on our web site along with the updated privacy policy. Your use of our web site and any other services provided by the Company after such changes are implemented constitutes your acknowledgement and acceptance of these changes. Please consult this privacy statement prior to every use for any changes.</p>

      <h5>15. Notice of Compliance to California Residents</h5>

      <p>California law requires that we provide you with a summary of your privacy rights under the California Online Privacy Protection Act (&ldquo;California Act&rdquo;) and the California Business and Professions Code. As required by the California Act, this privacy policy identifies the categories of personally identifiable information that we collect through our web site about individual consumers who use or visit our web site and the categories of third-party persons or entities with whom such personally identifiable information may be shared.</p>

      <p>Depending on the visitor's activity at our web site, certain &ldquo;personally identifiable information&rdquo; (as that term is defined in the California Act) may be collected, in addition to information set forth in other sections of this document. For purposes of the California Act, the term &ldquo;personally identifiable information&rdquo; means individually identifiable information about an individual consumer collected online by us from an individual and maintained by us in an accessible form, and may include any of the following:</p>

      <p>(1) A first and last name AND/OR company name; (2) A home or other physical address, including street name and name of a city or town; (3) An email address; (4) A telephone number; (5) Any other identifier that permits the physical or online contacting of a specific individual; (6) Information concerning a user that the web site collects online from the user, and maintains in personally identifiable form, in combination with an identifier described within this privacy policy.</p>

      <p>See Section 7 above entitled &ldquo;Integrity of Your Personal Information&rdquo; for a description of the process maintained by the Company for an individual consumer who uses or visits our web site to review and request changes to any of his or her personally identifiable information that is collected through our web site.</p>

      <p>See Section 14 above entitled &ldquo;Effective Date; Updates to Privacy Policy&rdquo; for a description of the process by which the Company notifies consumers who use or visit our web site of material changes to the Company's privacy policy. The effective date of this privacy policy is also included in Section 14 above.</p>
    </div>
  )
}
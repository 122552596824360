import { combineReducers } from 'redux';
import modal from './modal/reducer';
import sideNavigation from './sideNavigation/reducer';
import contactUs from './contactUs/reducer';

const rootReducer = combineReducers({
  modal,
  sideNavigation,
  contactUs,
});

export default rootReducer;

import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";

// import Logo from '../../assets/images/header/logo.png';
import IconGoals from '../../assets/images/icons/security-50x50.png';
// import IconMetrics from '../../assets/images/icons/icon-metrics.jpg';
// import IconAssociate from '../../assets/images/icons/icon-associate.jpg';
// import IconAnalysis from '../../assets/images/icons/icon-associate.jpg';
// import IconSupport from '../../assets/images/icons/icon-support.jpg';
// import IconHelp from '../../assets/images/icons/icon-help.jpg';

export default function MenuModal(props) {
  const history = useHistory();
  const { openModal, closeModal } = props;

  const handleMoveLink = link => () => {
    history.push(`/${link}`);
    closeModal();
  }

  return (
    <Modal show={openModal} onHide={closeModal}  dialogClassName={"menu-modal"}>
      <Modal.Body>
        <div className="menu-logo-wrapper">
          {/* <img src={Logo} className="menu-logo" alt="" /> */}
        </div>
        <div className="menu-list">
          <ul className="list-group">
            <li className="list-group-item menu-list-item" onClick={handleMoveLink('goals')}><img src={IconGoals} className="menu-icon" alt="" />Goals</li>
            <li className="list-group-item menu-list-item" onClick={handleMoveLink('metrics')}><img src={IconGoals} className="menu-icon" alt="" />Metrics</li>
            <li className="list-group-item">
              <div className="mb-2"><img src={IconGoals} className="menu-icon" alt="" />Associate</div>
              <ul className="list-group">
                <li className="list-group-item menu-list-item" onClick={handleMoveLink('goalAreaToGoal')}>Goal Area/Goals</li>
                <li className="list-group-item menu-list-item" onClick={handleMoveLink('goalToMetric')}>Goals/Metrics</li>
              </ul>
            </li>
            <li className="list-group-item menu-list-item" onClick={handleMoveLink('analysis')}><img src={IconGoals} className="menu-icon" alt="" />Analysis</li>
            <li className="list-group-item menu-list-item" onClick={handleMoveLink('support')}><img src={IconGoals} className="menu-icon" alt="" />Support</li>
            <li className="list-group-item menu-list-item" onClick={handleMoveLink('help')}><img src={IconGoals} className="menu-icon" alt="" />Help</li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  )
}
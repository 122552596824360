import React from 'react';


export default function ProductCarousel(props) {
	const { item } = props;

	return (
		<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block w-100" src="/images/documentation/Connections-Add-1.png" alt="Connections" />
                  </div>
                  <div class="carousel-item">
                  <img class="d-block w-100" src="/images/documentation/Connections-Edit-2.png" alt="Connection Detail" />
                  </div>
                  <div class="carousel-item">
                    <img class="d-block w-100" src="/images/documentation/Connections-Edit-1.png" alt="Connection Detail" />
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>              
              
	)
}
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Logo from '../../../assets/images/logo/logo_transparent_background.png';
import MenuTabItem from '../../../components/Tab/MenuTabItems';

import { openSideNavigation } from '../../../store/sideNavigation/actions';

const menuItemData = [
  {
    id: 1,
    title: 'Home',
    link: '/'
  },
  {
    id: 2,
    title: 'Services',
    link: '/services'
  },
  // {
  //   id: 3,
  //   title: 'Products',
  //   link: '/products'
  // },
  {
    id: 4,
    title: 'About',
    link: '/about'
  },
];

export default function Header(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const navigationData = useSelector((state) => state.sideNavigation.navigationData);

  const [activeMenu, setActiveMenu] = useState(menuItemData[0]);

  useEffect(() => {
    if (path !== activeMenu?.link) {
      setActiveMenu(null);
    }
  }, [path, activeMenu])

  const handleClickLogo = () => {
    history.push(`/`);
    setActiveMenu(1);
  }

  const handleClickMenu = (menuItem) => {
    history.push(`${menuItem.link}`);
    setActiveMenu(menuItem);
  };

  const handleClickContactButton = () => {
    history.push(`/contact-us`);
    setActiveMenu(0);
  }

  const handleClickSideNavigationBar = useCallback(() => {
    dispatch(
      openSideNavigation({
        params: { 
          isOpen: !navigationData?.params?.isOpen,
          activeMenu: activeMenu
        },
      })
    );
  });

  return (
    <header className="header">
      <div className="main-header row align-items-center">
        <div className="col-lg-4 col-md-4 col-sm-12 app-logo-wrapper">
          <img src={Logo} className="app-logo" onClick={handleClickLogo} alt="" />
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 mt-3 d-flex justify-content-end">
          <div className="menu-list">
            {menuItemData.map((item) => (
              <MenuTabItem
                className="menu-item"
                key={item.title}
                item={item}
                onTabItemClicked={() => handleClickMenu(item)}
                isActive={activeMenu?.id === item.id || path === item.link}
              />
            ))}
            <button className="btn btn-red" onClick={handleClickContactButton}>Contact Us</button>
          </div>
        </div>
      </div>
      <div className="mobile-header">
        <div className="mobile-main-header">
          <img src={Logo} className="app-logo responsive" onClick={handleClickLogo} alt="" />
          <div className="side-navigation-bar" onClick={handleClickSideNavigationBar}><i className="fas fa-bars"></i></div>
        </div>
      </div>
    </header>
  );
}

import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import DeskImage from '../../assets/images/backgrounds/tidy-desk-in-window-light-scaled.jpg';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import SliderItem from '../../components/Slider/SliderItem';

import IconDataAnalytic from '../../assets/images/icons/data-analytics.png'
import IconDataSecurity from '../../assets/images/icons/security.png';
import IconDatabase from '../../assets/images/icons/database-2.png';
import IconInfrastructure from '../../assets/images/icons/infrastructure.png'

import IconSSIS from '../../assets/images/icons/SSIS_Logo.png';
import IconSSAS from '../../assets/images/icons/SSAS_Logo.png';
import IconSSRS from '../../assets/images/icons/SSRS_Logo.png';
import IconPowerBI from '../../assets/images/icons/PowerBI_Logo.png';


const helperList = [
  { id: 1, 
    title: 'Managed Services',
    description: 'Sleep easily at night knowing your data is in safe hands and performing at its best.',
    icon: IconDatabase,
  },
  { id: 2, 
    title: 'SQL Health Check',
    description: 'We check over 250 items to make sure your SQL environment is up to snuff.',
    icon: IconDataAnalytic,
  },
  { id: 3, 
    title: 'Staff Augmentation',
    description: 'Easily ramp up staff for one-off data warehousing/reporting projects',
    icon: IconDataSecurity,
  },
];

const productList = [
  { id: 1, 
    title: 'Purview SSRS Connector',
    description: 'Connect to your on-premise SQL Server Reporting Services instances to pull in report, dataset, and datasource assets into Purview.',
    icon: IconSSRS,
    badge: "Dec 2021",
  },
  { id: 2, 
    title: 'Purview Power BI Report Server Connector',
    description: 'Connect to your on-premise Power BI Report Server so you can see lineage, related assets, and assign data owners/stewards to your reports.',
    icon: IconPowerBI,
    badge: "Dec 2021",
  },
  { id: 3, 
    title: 'Purview SSAS Connector',
    description: 'Harvest the meta data from your on-premise SQL Server Analysis Services instances and load it into your Purview data catalog.',
    icon: IconSSAS,
    badge: "2022",
  },
  { id: 4, 
    title: 'Purview SSIS Connector',
    description: 'Crawl through your on-premise SQL Server Integration Services packages to review high-level lineage for your data sources and destinations. Connect to either your SSIS Catalog, File System, or legacy MSDB.',
    icon: IconSSIS,
    badge: "2022",
  },
]

export default function Home() {

  return (
    <section className="home-page-section">
      <section className="main-slider">
        <div className="desktop-home-main-slider d-flex align-items-center">
          <div className="col-lg-3 col-md-5 col-sm-12">
            <div className="home-slider-title-wrapper">
              <div className="home-slider-title">We provide reliable, high-quality technical skills and understanding to companies of all sizes that are seeking to streamline, automate, or understand their data processes.</div>
              {/* <div className="home-slider-subtitle">Less fires, more time working on your business</div> */}
              <div className="home-slider-contact-wrapper">
                <button className="btn btn-red">Contact</button>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="home-desk-image-wrapper">
              <img src={DeskImage} className="home-desk-image" alt="desk" />
            </div>
          </div>
        </div>
        <div className="mobile-home-main-slider">
          <img src={DeskImage} className="home-desk-image" alt="desk" />
          <div className="mobile-home-slider-title">We provide reliable, high-quality technical skills and understanding to companies of all sizes that are seeking to streamline, automate, or understand their data processes.</div>
        </div>
      </section>
      <section className="main-content container">
        <section className="home-section-help">
          <div className="home-section-wrapper">
            <div className="home-section-help-title-wrapper">
              <div className="home-section-title">
                How we can help
              </div>
              <p className="home-section-description">We specialize in getting things done.  Agile is our bible. Whether you are looking to do things right the first time, or are working on getting a project back on track, we're in your pocket and here to help!</p>
            </div>
            <div className="home-help-list-wrapper row">
              {helperList.map((helperItem, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 mt-2" key={index}>
                  <div className="card home-helper-list-item">
                    <div className="home-helper-item-icon-wrapper">
                      <img src={helperItem.icon} className="home-help-item-icon" alt="helper-icon" />
                    </div>
                    <div className="home-section-title home-helper-item-title mt-4">{helperItem.title}</div>
                    <p className="home-section-description">{helperItem.description}</p>
                    <div className="home-helper-button"><PrimaryButton text="Learn More" /></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <section className="home-section-help">
          <div className="home-section-wrapper">
            <div className="home-section-title">
              Products
            </div>
            <p className="home-section-description">Interested in Azure Purview data governance software? We have several on-prem connectors that integrate seamlessly with Purview coming in 2021!</p>
            <div className="home-help-list-wrapper row">
              {productList.map((product, index) => (
                <div className="col-lg-3 col-md-6 col-sm-12 mt-2" key={index}>
                  <div className="card home-helper-list-item">
                    {product.badge != "" && 
                      <div class="ribbon">
                        <span>
                          {product.badge}
                        </span>
                      </div>
                    }
                    <div className="home-helper-item-icon-wrapper">
                      <img src={product.icon} className="home-help-item-icon" alt="helper-icon" />
                    </div>
                    <div className="home-product-item-title mt-4">{product.title}</div>
                    <p className="home-section-description">{product.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}
      </section>
    </section>
  )
}
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';

import { openSideNavigation } from '../../store/sideNavigation/actions';

import sideNavigationConfig from '../../configs/sideNavigationConfig';

export default function SideNavigation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const navigationData = useSelector((state) => state.sideNavigation.navigationData);

  const handleCloseDeviceNav = useCallback(() => {
    dispatch(
      openSideNavigation({
        params: { isOpen: false },
      })
    );
  });

  const handleClickSideNavMenu = useCallback((item) => {
    history.push(`${item?.navLink}`);
    handleCloseDeviceNav();
  });

  const selectedMenu = "side-nav-menu-group-header selected-side-nav-menu-group-header";
  const unSelectedMenu = "side-nav-menu-group-header";

  return (
    <div className={navigationData?.params?.isOpen ? 'container-fluid side-navigation' : 'side-navigation close-side-navigation'}>
      <div className="side-navigation-close-icon" onClick={handleCloseDeviceNav}><i className="fal fa-times"></i></div>
      <div className="side-navigation-button-wrapper row">
        <div className="col-lg-12 col-md-12 col-sm-12 my-2">
          <button className="btn second-button">Contact Us</button>
        </div>
      </div>
      <div className="side-nav-menu-list">
        {sideNavigationConfig.map((item) => (
          <div className="side-nav-menu" key={item.id}>
            {item.type === 'groupHeader' && (
              <div className={location.pathname === item.navLink ? selectedMenu : unSelectedMenu } onClick={() => handleClickSideNavMenu(item)}>
                {item.groupTitle}
              </div>
            )}
            {item.type === 'groupItem' && (
              <div className="side-nav-menu-group-item">
                {item.title}
              </div>
            )}
            {item.type === 'groupHeaderItem' && (
              <div className="side-nav-menu-group-header-item">
                {item.groupTitle}
              </div>
            )}
          </div>
        )) }
      </div>
    </div>
  );
}

SideNavigation.propTypes = {
  // devices: PropTypes.array,
};

SideNavigation.defaultProps = {
  // devices: [],
};

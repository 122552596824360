import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { addSubscriber, sendEmail } from '../../../../store/contactUs/actions';
import { SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE, ADD_SUBSCRIBER_SUCCESS, ADD_SUBSCRIBER_FAILURE } from '../../../../store/contactUs/constants';

export default function ContactFooter(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ firstName: '', lastName: '', email: '', subject: '', message: '' });

  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));

  const handleSubmit = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (submitting) return;
      setSubmitting(true);

      const message = `New message from website.
        First Name:` + formState.firstName + `
        Last Name: ` + formState.lastName  + `
        Email Address: ` + formState.email + `
        Subject: ` + formState.subject + `
        Message: ` + formState.message

      const email = {
        // ToEmail: formState.firstName + ' ' + formState.lastName,
        // ToUsername: formState.email,
        // Subject: formState.subject,
        // PlainTextContent: formState.message,
        
        FromEmail: 'info@CipherDataSolutions.com',
        FromUserName: 'Cipher Data Solutions',
        
        ToEmail: 'info@CipherDataSolutions.com',
        ToUsername: 'Cipher Data Solutions',
        
        Subject: 'New message from website',
        PlainTextContent: message,
      };

      const user = {
        FirstName: formState.firstName,
        LastName: formState.lastName,
        EmailAddress: formState.email,
        CompanyName: formState.company,
        JobTitle: formState.jobTitle,
        SignupSource: 'Website',
      };

      //console.log(email);

      const emailResult = await dispatch(sendEmail(email));
      const subscribeResult = await dispatch(addSubscriber(user));

      const r = await dispatch();
      
      if (emailResult.type === SEND_EMAIL_SUCCESS || subscribeResult.type == ADD_SUBSCRIBER_SUCCESS) {
        const resData = emailResult.payload 
        //toast.success('Sent email successful');
        history.push(`/contact-confirm`);
      }

      if (emailResult.type === SEND_EMAIL_FAILURE || subscribeResult.type == ADD_SUBSCRIBER_FAILURE) {
        const errorMessage =
          typeof emailResult.errors === 'string' ? emailResult.errors : 'Failed to send message';
        toast.error(errorMessage);
        //console.log(errorMessage);
      }
      setSubmitting(false);
    } catch (err) {
      const errorMessage = typeof err.message === 'string' ? err.message : 'Could not send message';
      toast.error(errorMessage);
      //console.log(errorMessage);
      setSubmitting(false);
    }

  });

  return (
    <footer className="footer container">
      <section className="contact-us">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 mt-5">
            <div className="card join-card">
              <div className="join-card-wrapper">
                <div className="join-title-wrapper">
                  <div className="join-wrapper-tile">Contact Us</div>
                </div>
                <form className="join-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>First Name</label>
                        <input type="text" name="firstName" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" name="lastName" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="email" name="email" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Company</label>
                        <input type="text" name="company" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Job Title</label>
                        <input type="text" name="jobTitle" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Subject</label>
                        <input type="text" name="subject" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Message</label>
                        <textarea rows="5" name="message" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-check">
                        <input type="checkbox" name="agreeState" className="form-check-input" id="agree-state" required/>
                        <label className="form-check-label join-form-label" htmlFor="agree-state">By checking, I agree to share my form responses.</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex justify-content-center mb-3">
                      <button type="submit" className="btn btn-red">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 mt-5">
            <div className="contact-us-wrapper">
              <div className="contact-us-tile">Contact Information</div>
              <div className="home-section-description pr-4 mb-4">Reach out to us! We're always happy to answer questions, start conversations, or provide guidance when we can.</div>
              <div className="contact-info">
                <div className="contact-info-wrapper">
                  <div className="contact-info-label">Phone</div>
                  <div className="contact-info-value">214-307-2325</div>
                </div>
                <div className="contact-info-wrapper">
                  <div className="contact-info-label">Email</div>
                  <div className="contact-info-value">info@CipherDataSolutions.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

import React from 'react';

export default function PageNotFound() {
  return (
    <section className="page-not found main row">
      <h1 className="text-center mt-5">404 - Page Not Found</h1>
      <p className="text-center mt-4 h5">Uh oh! Not sure what happened here...</p>
    </section>
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function About() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="about-page container">
      <section className="about-section">
        <div className="section-about-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="about-section-title-wrapper">
                <div className="home-section-title">
                  WHO WE ARE
                </div>
                <p className="about-section-description">
                  We are a group of data professionals that are looking to change the way companies manage their databases. We are passionate about maintaining integrity and adding value, quality, and reliability to ensure your success.<br /><br />

                  Cipher Data Solutions was established in 2016 by the owner, Colten Goll. Since then, we have helped many companies in their day-to-day management, as well as delivered several large scale, multi-million dollar projects. Where other companies may fail in their lack of expertise, inability to hit a deadline, or providing cost effective solutions, we have a proven track record of all three.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="about-section-image-wrapper"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="section-about-wrapper">
          <div className="home-section-title">WHY CHOOSE US</div>
          <p className="home-section-description">You can depend on us to keep your SQL systems healthy</p>
          <div className="about-section-description-list">
            <div className="about-section-description-wrapper">
              <b className="about-section-description-title"><i className="fa fa-check" aria-hidden="true"></i> Proactive</b>
              <p className="about-section-description">Our proactive monitoring allows you to get out ahead of any issues lurking that could affect your business.</p>
            </div>
            <div className="about-section-description-wrapper">
              <b className="about-section-description-title"><i className="fa fa-check" aria-hidden="true"></i> Quality</b>
              <p className="about-section-description">Our reputation is very important to us. We strive for excellence in everything we do.</p>
            </div>
            <div className="about-section-description-wrapper">
              <b className="about-section-description-title"><i className="fa fa-check" aria-hidden="true"></i> Business Minded</b>
              <p className="about-section-description">We know the importance of your business. Our primary goal is to help you achieve your goals and increase your bottom line.</p>
            </div>
            <div className="about-section-description-wrapper">
              <b className="about-section-description-title"><i className="fa fa-check" aria-hidden="true"></i> Timely</b>
              <p className="about-section-description">We deliver projects on-time, every time.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
import React from 'react';

import LandingBg from '../../assets/images/landing/background.png';
import ComputerDesk from '../../assets/images/landing/computerdesk.png';

export default function Landing() {
  return (
    <div className="main landing">
      <div className="landing-image-wrapper">
        <img src={LandingBg} className="landing-image" alt="landing-image" />
      </div>
      <div className="d-flex align-items-center landing-content">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <div className="landing-title-wrapper">
            <div className="landing-title">Drive More Customers Through Digital</div>
            <div className="landing-subscription">Our goal is to provide reliable, high-quality technical skills and understanding to companies of all sizes that are seeking to streamline, automate, or understand their data processes.</div>
            <div className="lading-button-wrapper">
              <a href="#" className="btn btn-get-started">Get Started</a>
              <button className="btn btn-sign-up">Sign Up For Free</button>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <img src={ComputerDesk} className="landing-image-computerDesk" alt="landing-image-computerDesk" />
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from '../../layouts/Main/components/Footer/DownloadFooter';

export default function PurviewSetupScript() {
  toast.configure();

  const notify = () => {
    toast("Default Notification !");

    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_CENTER
    });

    toast.error("Error Notification !", {
      position: toast.POSITION.TOP_LEFT
    });

    toast.warn("Warning Notification !", {
      position: toast.POSITION.BOTTOM_LEFT
    });

    toast.info("Info Notification !", {
      position: toast.POSITION.BOTTOM_CENTER
    });

    toast("Custom Style Notification with css class!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'foo-bar'
    });
  };

  return (
    <>
      <section className="purview-setup-script-page main container">
        <h3 className="centered-h3">Thanks for inquiring about the automation script to setup your Azure Purview instance!</h3>
      </section>
      <Footer  />
    </>
  )
}
import Router from "./Router"
import { useTracking } from './until/useTracking'

// function App() {
  
//   useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID)

//   return (
//     <Router />
//   );
// }

export const App = () => {
  
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID)

  return (
    <Router />
  );
}
export default App;

const navigationConfig = [
  {
      type: 'groupHeader',
      id: 'home',
      groupTitle: 'Home',
      navLink: '/',
  },
  // {
  //   type: 'groupHeader',
  //   id: 'products',
  //   groupTitle: 'Products',
  //   navLink: '/products',
  // },
  // {
  //   type: 'groupItem',
  //   id: 'residential',
  //   title: 'Residential',
  //   navLink: '/properties/residential',
  // },
  // {
  //   type: 'groupItem',
  //   id: 'commercial',
  //   title: 'Commercial',
  //   navLink: '/properties/commercial',
  // },
  // {
  //   type: 'groupItem',
  //   id: 'for-sale',
  //   title: 'For Sale',
  //   navLink: '/properties/for-sale',
  // },
  // {
  //   type: 'groupHeader',
  //   groupTitle: 'Services',
  //   id: 'services',
  //   navLink: '/services',
  // },
  {
    type: 'groupHeader',
    groupTitle: 'About',
    id: 'about',
    navLink: '/about',
  },
  // {
  //   type: 'groupHeaderItem',
  //   id: 'news',
  //   groupTitle: 'News',
  //   navLink: '/news',
  // },
];

export default navigationConfig;
  
import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from '../../layouts/Main/components/Header';
import Modal from '../../components/Modal';

const MainLayout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <div className="container-fluid app">
        <Header />
        {children}
      </div>
      <Modal />
    </>
  )
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default MainLayout;

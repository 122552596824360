import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetDialog from "sweetalert2";

import MembershipItem from '../../components/MembershipItem';

import IconDataAnalytic from '../../assets/images/icons/data-analytics.png'
import IconDataSecurity from '../../assets/images/icons/security.png';
import IconDatabase from '../../assets/images/icons/database-2.png';
import IconInfrastructure from '../../assets/images/icons/infrastructure.png';


const serviceList = [
  { id: 1, 
    title: 'Managed Services',
    description: 'Sleep easily at night knowing your data is in safe hands and performing at its best.',
    icon: IconDatabase,
  },
  { id: 2, 
    title: 'SQL Health Check',
    description: 'We check over 250 items to make sure your SQL environment is up to snuff.',
    icon: IconDataAnalytic,
  },
  { id: 3, 
    title: 'Staff Augmentation',
    description: 'Easily ramp up staff for one-off data warehousing/reporting projects',
    icon: IconDataSecurity,
  },
];

const membershipList = [
  { 
    id: 1, 
    title: 'Standard',
    price: '19.99',
    description: 'Perfect for individuals Up to 10 products',
  },
  { 
    id: 2, 
    title: 'Business',
    price: '29.99',
    description: 'Perfect for small companies Up to 100 products',
  },
  { 
    id: 3, 
    title: 'Database Management',
    price: '39.99',
    description: 'Perfect for large companies Unlimited products',
  },
]

export default function Services() {
  const dispatch = useDispatch();
  const history = useHistory();


  const handleClickContactButton = () => {
    history.push(`/contact-us`);
  }

  return (
    <div className="service-page">
      <section className="service-page-title-wrapper">
        <div className="service-page-title">Our Services</div>
        <div className="service-page-description">
          We provide a wide range of professional services to meet your needs. We promise to provide every service with a smile, and to your highest level of satisfaction. 
        </div>
      </section>
      <section className="main-content container">
        <section className="service-section service-section-service-items">
          <div className="service-section-wrapper">
            <div className="service-item-list-wrapper row">
              {serviceList.map((serviceItem, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 mt-2" key={index}>
                  <div className="service-item">
                    <div className="service-item-icon-wrapper">
                      <img src={serviceItem.icon} className="service-item-icon" alt="helper-icon" />
                    </div>
                    <div className="service-section-title service-item-title mt-4">{serviceItem.title}</div>
                    <p className="service-section-description">{serviceItem.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
      <section className="service-section service-section-booking">
        <div className="service-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
              <div className="service-section-title">Trust the Data Experts</div>
              <div className="service-section-subtitle">We offer a wide range of services to make sure your data is protected, available, and usable.</div>
              <p className="service-section-description mb-4">
                If you're looking to feel more comfortable with your data estate, reach out and let us see if we're a good fit.  We're happy to help in any way we can, even if it means pointing you to the right person/company that can help the most.
              </p>
                <button className="btn btn-red" onClick={handleClickContactButton}>Book Service</button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
              <div className="booking-image-wrapper">
                <img 
                  src='https://lirp-cdn.multiscreensite.com/md/dmtmpl/dms3rep/multi/opt/businessman-using-a-computer-talking-on-the-mobile-phone-1920w.jpg' className="booking-image" 
                  alt="booking-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="service-section service-section-membership">
        <div className="service-section-wrapper">
          <div className="row">
            {membershipList.map((membership, index) => (
              <div className="col-lg-4 col-md-4 col-sm-4" key={index}>
                <MembershipItem item={membership} />
              </div>
            ))}
          </div>
        </div>
      </section> */}
      {/* <section className="service-section service-section-booking-appointment">
        <div className="service-section-wrapper">
          <div className="service-page-title service-section-booking-appointment-title">Interested in our services? We’re here to help!</div>
          <div className="service-page-description mb-5">
            We want to know your needs exactly so that we can provide the perfect solution. Let us know what you want and we’ll do our best to help.
          </div>
          <a href="#" className="btn btn-white">Book an appointment</a>
        </div>
      </section> */}
    </div>
  )
}
import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../layouts/Main/components/Header';
import Footer from './components/Footer/ContactFooter';
import SidebarNavigation from '../../../src/components/SideNavigation';
import Modal from '../../components/Modal';

const MainLayout = ({ children }) => {

  return (
    <>
      <div className="container-fluid app">
        <Header />
        <div className="main">
          {children}
        </div>
        <Footer />
      </div>
      <SidebarNavigation />
      <Modal />
    </>
  )
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default MainLayout;

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function Documentation() {
    const dispatch = useDispatch();

    return (
        //        <!-- Document Wrapper   
        //        =============================== -->
        <div id="main-wrapper documentation-page-title row">
            <div class="documentation-page-title">
                <h1 class="text-center">Purview Connectors - Documentation</h1>
                
            </div>
            {/* <!--- Header 
           ============================ ---> */}

            {/* <!-- Header End -->  */}

            {/* <!-- Content
          ============================ --> */}
            <div id="content" role="main">

                {/* <!-- Sidebar Navigation
            ============================ --> */}
                <div class="idocs-navigation bg-light documentation-navbar" id="idocs-navigation">
                    <ul class="nav flex-column ">
                        <li class="nav-item"><a class="nav-link active" href="#PurviewSetup">Azure Purview Setup</a>
                            <ul class="nav flex-column">
                                <li class="nav-item"><a class="nav-link" href="#PurviewSetup_RegisterProvider">Register Provider</a></li>
                                <li class="nav-item"><a class="nav-link" href="#PurviewSetup_RegisterApplication">Register an Application</a></li>
                                <li class="nav-item"><a class="nav-link" href="#PurviewSetup_ApplicationPermissions">Application Permissions</a></li>
                                <li class="nav-item"><a class="nav-link" href="#PurviewSetup_SetServicePermissions">Permissions</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a class="nav-link" href="#Configuration">Configuration</a></li>
                        <li class="nav-item"><a class="nav-link" href="#Connections">Connections</a>
                            <ul class="nav flex-column">
                                <li class="nav-item"><a class="nav-link" href="#Connections_AddConnection">Add Connection</a></li>
                                <li class="nav-item"><a class="nav-link" href="#Connections_EditConnection">Edit Connection</a></li>
                                <li class="nav-item"><a class="nav-link" href="#Connections_DeleteConnection">Delete Connection</a></li>
                                <li class="nav-item"><a class="nav-link" href="#Connections_RunScan">Run Scan</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a class="nav-link" href="#FAQs">FAQs</a></li>
                    </ul>
                </div>

                {/* <!-- Docs Content
            ============================ --> */}
                <div class="idocs-content documentation-main-content">
                    <div class="container documentation-main-content">
                        <section id="PurviewSetup">
                            <h2>Azure Purview Setup</h2>
                            <p class="lead">Before you can get going with scanning your on-premise data sources with the Purview Connectors application, you'll need to set up a few things inside of your Azure tenant.</p>
                            <ol>
                                <li class="list-group-item border-0">Register Purview Provider</li>
                                <li class="list-group-item border-0">Register an Application</li>
                                <li class="list-group-item border-0">Application Permissions</li>
                                <li class="list-group-item border-0">Set Service Permissions</li>
                            </ol>
                        </section>
                        <section id="PurviewSetup_RegisterProvider">
                            <h3>Register Provider</h3>
                            <p class="lead">If you've already created your Azure Purview account, you may have already done this.  If you have, simple move on to the next step.</p>
                            <p>To register Purview as a provider, you will need to open the Azure portant and navigate to the subscription in which you'd like to create a Purview account, then choose "Resource Providers" from the navigation menu in the Settings section.</p>
                            <img src="/images/documentation/ResourceProvider-1.png" class="img-fluid" alt="Resource Providers"></img>
                            <p>Either scroll down and find "Microsoft.Purview" or filter using the search bar. From there simply ensure it is registered.</p>
                            <img src="/images/documentation/ResourceProvider-2.png" class="img-fluid" alt="Resource Providers"></img>
                            <div class="col-xs-12 h50"></div>
                        </section>

                        <section id="PurviewSetup_RegisterApplication">
                            <h3>Register an Application</h3>
                            <p>You now need to setup the authentication for the Purview Connectors application to connect to your Azure Purview account.  This is done via an App Registration.</p>
                            <p>In your Azure portal, navigate to the Azure Active Directory and choose "App Registrations"</p>
                            <img src="/images/documentation/RegisterApplication-1.png" class="img-fluid" alt="App Registration"></img>
                            <p>Click on new registration and give the application a name. Remember, this will be used to authenticate between Purview Connectors and your Purview account in Azure.</p>
                            <img src="/images/documentation/RegisterApplication-2.png" class="img-fluid" alt="App Registration"></img>
                            <p>Once you have registered the application, you'll need to grant it API Permissions to the Purview API.  So, click on API Permissions on the navigation pane, then find Azure Purview and click on that.</p>
                            <img src="/images/documentation/RegisterApplication-3.png" class="img-fluid" alt="App Registration"></img>
                            <p>Now, choose "Application permissions" and check the "Purview Application API Access".</p>
                            <img src="/images/documentation/RegisterApplication-4.png" class="img-fluid" alt="App Registration"></img>
                            <p>Note: You will need someone with admin rights to come in and click "Grant admin consent for ..." before the permission is enabled.</p>
                            <img src="/images/documentation/RegisterApplication-5.png" class="img-fluid" alt="App Registration"></img>
                            
                            <p>Finally, you need to create a secret by clicking on "Certificates and Secrets" in the navigation pane.</p>
                            <img src="/images/documentation/RegisterApplication-6.png" class="img-fluid" alt="App Registration"></img>
                            <p>Click "New client secret", then give it an appropriate name and expiration.</p>
                            <img src="/images/documentation/RegisterApplication-7.png" class="img-fluid" alt="App Registration"></img>
                            <p>You should now see the Secret's Value.  You'll want to copy that and save it for later when configuring Purview Connectors.</p>                            
                        </section>

                        <section id="PurviewSetup_ApplicationPermissions">
                        <h3>Application Permissions</h3>
                            <p>You will need to log in to you Purview account and grant the application you've register the "Data Curator" role inside of Azure Purview.  To do so, navigate to the Data Map, and choose "Collections". You'll then need to add a role assignment for the application under the "Data Curators" section.</p>
                            <img src="/images/documentation/Permissions-1.png" class="img-fluid" alt="App Registration"></img>
                        </section>
                        <section id="PurviewSetup_SetServicePermissions">
                            <h3>Purview Connectors Service Permissions</h3>
                            <p>Now on the machine you've installed Purview Connectors on, you will need to ensure that the windows service, "Purview Connectors", is run under the account in which you want to connect to your data sources.</p>
                        </section>
                        <hr class="divider" />

                        <section id="Configuration">
                            <h2>Configuration</h2>
                            <p>Now that you've set everything up in Purview, you can open the Purview Connectors user interface and go to the configuration tab.</p>
                            <img src="/images/documentation/Configuration-1.png" class="img-fluid" alt="Configuration"></img>
                            <p class="lead">There are four values you'll need to grab from your Azure portal.</p>
                            <ol>
                                <li>Tenant ID
                                    <p class="ml20">You can grab this by going to back to your app registration (Azure AD {'>'} App Registration {'>'} Application you created ). On the overview page, you'll find the Tenant ID.</p>
                                    <img src="/images/documentation/Configuration-2.png" class="img-fluid" alt="App Registration"></img>
                                </li>
                                <li>Client ID
                                    <p class="ml20">On the same overview page, you'll find the Application (client) ID.</p>
                                </li>
                                <li>Client Secret
                                    <p class="ml20">Click on "Certificates & Secrets" and find the value of the secret you created earlier.</p>
                                    <img src="/images/documentation/Configuration-3.png" class="img-fluid" alt="App Registration"></img>
                                </li>
                                <li>Account Name
                                    <p class="ml20">The account name is the actual name of the Purview account inside of Azure.  So, navigate to your Purview Accounts, and find the name of the account you want to use.</p>
                                    <img src="/images/documentation/Configuration-4.png" class="img-fluid" alt="App Registration"></img>
                                </li>
                            </ol>
                        </section>

                        <section id="Connections">
                            <h2>Connections</h2>
                            <p class="lead">The connections tab is a list of data sources in which you want to scan and populate data into your Azure Purview account.</p>
                        </section>

                        <section id="Connections_AddConnection">
                            <h3>Add Connection</h3>
                            <p>To add a connection, click on the "Add Connection" button.</p>
                            <img src="/images/documentation/Connections-Add-1.png" class="img-fluid" alt="Add Connection"></img>
                            <p>The first item to fill out is the Connector Type. This tells the application what type of data source to scan. The dropdown will only display the list of connectors you have a license for.</p>
                            <img src="/images/documentation/Connections-Add-2.png" class="img-fluid" alt="Connector Type"></img>
                            <p>Then, give the connection a friendly name.</p>
                            <p>The connection URL is the location of the report server.</p>  
                            <div class="bg-light">
                                <p class="lead ml20">    For SQL Server Reporting Services up to 2016, the format should be http://servername/reportserver.</p>
                                <p class="lead ml20">    For SQL Server Reporting Services 2017+, the format should be http://servername/reports.</p>
                            </div>
                            <p>Finally, click "Save Changes".  This data source will now be scanned by the background service according the schedule you specified.</p>
                        </section>

                        <section id="Connections_EditConnection">
                            <h3>Edit Connection</h3>
                            <p>When you click on a connection in your list of connections, it will expand to show the details of the connection.  From there you'll see several buttons.</p>
                            <img src="/images/documentation/Connections-Edit-1.png" class="img-fluid" alt="Edit Connection"></img>
                            <p>If you wish to edit the connection, click on "Edit Details", which will open a page that allows you to edit any of the details.</p>
                            <img src="/images/documentation/Connections-Edit-2.png" class="img-fluid" alt="Edit Connection"></img>
                        </section>

                        <section id="Connections_DeleteConnection">
                            <h3>Delete Connection</h3>
                            <p>To delete the connection, click on the "Delete Connection" button.</p>
                            <img src="/images/documentation/Connections-Delete-1.png" class="img-fluid" alt="Delete Connection"></img>
                        </section>

                        <section id="Connections_RunScan">
                            <h3>Run Scan</h3>
                            <p>If you'd like to execute a one-time out of cycle scan of the data source, click on "Execute Scan" and it will tell the background service to execute the scan now.</p>
                        </section>
                        <hr class="divider" />

                        <section id="FAQs">
                            <h2>Frequently Asked Questions</h2>
                            
                            <h3>Q: What is Purview Connectors?</h3>
                            <p>Purview Connectors is an application that extends the service of Microsoft's Azure Purview. It does so by providing data connectors that will scan, parse, and extract the metadata from you on-premise data sources.  It then sends the metadata to your own Azure Purview account.</p>
                            
                            <h3>Q: Do we need a specific version of .NET installed?</h3>
                            <p>No, Purview Connectors is published as a self-contained application, which means it has all of the .NET runtime and libraries needed for it to run.</p>
                            
                            <h3>Q: How is Purview Connectors Licensed?</h3>
                            <p>Purview Connectors is licensed through the Azure Marketplace. Each type of connector (SSRS, PBIRS, SSAS, SSIS) requires a license, but you can scan as many data sources (connections) for your licensed connector type.</p>
                            
                            <h3>Q: Can you just set up our whole Purview account for us?</h3>
                            <p>Yes!  We can set everything up for you. We've been working with Purview since it was first in preview and can help provide best practices and procedures.  Just <a href="https://cipherdatasolutions.com/contact-us" target="_blank">reach out to us</a> and we'll be happy to help!</p>

                            <h3>Q: Where are the logs for the service/user interface?</h3>
                            <p>The logs are saved in the location where you installed the application, under a folder called "LOGS". So if you chose the default installation path, it would be "C:\Program Files (x86)\Cipher Data Solutions\Purview Connectors\LOGS".</p>
                            
                            <h3>Q: Can I change what is logged?</h3>
                            <p>Yes, you can. In the application location, there is a file called log4net.config.  You can modify this and add any option that is supported by <a href ="http://logging.apache.org/log4net/release/config-examples.html" target="_blank">log4net.</a></p>
                            
                        </section>
                    </div>
                </div>
            </div>
            {/* <!-- Content end --> 
          
          <!-- Footer --> */}

            {/* <!-- Footer end -->  */}

        </div>

        

        //        <!-- Document Wrapper end --> 

        //     <div className="documentation-page">
        //         <section className="documentation-page-title">
        //             <section className="main-content container">
        //                 <section className="documentation-product documentation-section-product-list">
        //                     <div className="home-section-wrapper">
        //                         <div className="home-section-title">
        //                             Purview Connectors Documentation
        //                         </div>
        //                         <div className="documentation-item-list-wrapper row">

        //                         </div>
        //                     </div>
        //                 </section>
        //             </section>
        //         </section>
        //     </div>
        // 
    )
}
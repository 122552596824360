import React from 'react';
import PropTypes from 'prop-types';

const MenuTabItemComponent = ({ item, onTabItemClicked = () => {}, isActive = false }) => {

  return (
    <>
      {isActive ? (
        <div className="menu-tab-item" onClick={onTabItemClicked}>
          <div className="menu-item-title">
            {item.title}
          </div>
        </div>
      ) : (
        <div className="inactive-menu-tab-item" onClick={onTabItemClicked}>
          <div className="menu-item-title">{item.title}</div>
        </div>
      )}
    </>
  );
};

MenuTabItemComponent.propTypes = {
  title: PropTypes.object,
  isActive: PropTypes.bool,
  onTabItemClicked: PropTypes.func,
};

MenuTabItemComponent.defaultProps = {
  title: null,
  isActive: false,
  onTabItemClicked: null,
};

export default MenuTabItemComponent;

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IconSSIS from '../../assets/images/icons/SSIS_Logo.png';
import IconSSAS from '../../assets/images/icons/SSAS_Logo.png';
import IconSSRS from '../../assets/images/icons/SSRS_Logo.png';
import IconPowerBI from '../../assets/images/icons/PowerBI_Logo.png';

import PrimaryButton from '../../components/Buttons/PrimaryButton';

const serviceList = [
  { id: 1, 
    title: 'Purview SSRS Connector',
    description: 'Connect to your on-premise SQL Server Reporting Services instances to pull in report, dataset, and datasource assets into Purview.',
    icon: IconSSRS,
    learnMorePage: "/purview-connectors-ssrs",
  },
  { id: 2, 
    title: 'Purview Power BI Report Server Connector',
    description: 'Connect to your on-premise Power BI Report Server so you can see lineage, related assets, and assign data owners/stewards to your reports.',
    icon: IconPowerBI,
    learnMorePage: "/purview-connectors-powerbi-report-server",
  },
  { id: 3, 
    title: 'Purview SSIS Connector',
    description: 'COMING SOON - Crawl through your on-premise SQL Server Integration Services packages to review high-level lineage for your data sources and destinations. Connect to either your SSIS Catalog, File System, or legacy MSDB.',
    icon: IconSSIS,
    learnMorePage: "/purview-connectors-ssis",
  },
  { id: 4, 
    title: 'Purview SSAS Connector',
    description: 'COMING SOON - Harvest the meta data from your on-premise SQL Server Analysis Services instances and load it into your Purview data catalog.',
    icon: IconSSAS,
    learnMorePage: "/purview-connectors-ssas",
  },
];

export default function Products() {
  const dispatch = useDispatch();
  const history = useHistory();

  
  return (
    <div className="service-page">
      <section className="service-page-title-wrapper">
        <div className="service-page-title">Product Features</div>
        <div className="service-page-description">
          We provide a wide range of professional services to meet your needs. We promise to provide every service with a smile, and to your highest level of satisfaction. 
        </div>
      </section>
      <section className="main-content container">
        <section className="service-product service-section-product-list">
          <div className="home-section-wrapper">
            <div className="home-section-title">
              Purview On-Prem Connectors
            </div>
            <div className="service-item-list-wrapper row">
              {serviceList.map((serviceItem, index) => (
                <div className="col-lg-3 col-md-6 col-sm-12 mt-2" key={index}>
                  <div className="card service-item">
                    <div className="service-item-icon-wrapper">
                      <img src={serviceItem.icon} className="service-item-icon" alt="helper-icon" />
                    </div>
                    <div className="service-section-title service-item-title mt-4">{serviceItem.title}</div>
                    <p className="product-section-description">{serviceItem.description}</p>
                    <div className="home-helper-button"><PrimaryButton text="Learn More" page={serviceItem.learnMorePage} /></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
    </div>
  )
}
import * as http from '../../until/AxiosInstance';

import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  DOWNLOAD_ATTACHED_FILE_REQUEST,
  DOWNLOAD_ATTACHED_FILE_SUCCESS,
  DOWNLOAD_ATTACHED_FILE_FAILURE,
  ADD_SUBSCRIBER_REQUEST,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILURE,
} from './constants';

const sendEmailRequest = () => ({ type: SEND_EMAIL_REQUEST });
const sendEmailSuccess = (payload) => ({ type: SEND_EMAIL_SUCCESS, payload });
const sendEmailFailure = (errors) => ({ type: SEND_EMAIL_FAILURE, errors });

const addSubscriberRequest = () => ({ type: ADD_SUBSCRIBER_REQUEST });
const addSubscriberSuccess = (payload) => ({ type: ADD_SUBSCRIBER_SUCCESS, payload });
const addSubscriberFailure = (errors) => ({ type: ADD_SUBSCRIBER_FAILURE, errors });

export const sendEmail = (payload) => async (dispatch) => {
  dispatch(sendEmailRequest());
  
  //console.log('sending payload');
  //console.log(payload);

  try {
    const response = await http.json().post('/email/sendEmail', payload);
    
    //console.log('response:');
    //console.log(response.status);
    //console.log(response.data);

    if (response.status === 200) {
      return dispatch(sendEmailSuccess(response.data));
    }
    return dispatch(sendEmailFailure(response.data.message));
  } catch (e) {
    return dispatch(sendEmailFailure(e));
  }
};

const downloadAttachedFileRequest = () => ({ type: DOWNLOAD_ATTACHED_FILE_REQUEST });
const downloadAttachedFileSuccess = (payload) => ({ type: DOWNLOAD_ATTACHED_FILE_SUCCESS, payload });
const downloadAttachedFileFailure = (errors) => ({ type: DOWNLOAD_ATTACHED_FILE_FAILURE, errors });

export const downloadAttachedFile = (payload) => async (dispatch) => {
  dispatch(downloadAttachedFileRequest());
  try {

    const response = await http.zip().post(`/download/downloadAttachedFile`, payload);
    if (response.status === 200) {
      return dispatch(downloadAttachedFileSuccess(response.data));
    }
    return dispatch(downloadAttachedFileFailure(response.data.message));
  } catch (e) {
    return dispatch(downloadAttachedFileFailure(e));
  }
};

export const addSubscriber = (payload) => async (dispatch) => {
  dispatch(addSubscriberRequest());
  
  //console.log('sending payload');
  //console.log(payload);

  try {
    const response = await http.json().post(`/user/addSubscriber`, payload);
    
    //console.log('response:');
    //console.log(response.status);
    //console.log(response.data);

    if (response.status === 200) {
      return dispatch(addSubscriberSuccess(response.data));
    }
    return dispatch(addSubscriberFailure(response.data.message));
  } catch (e) {
    return dispatch(addSubscriberFailure(e));
  }
};

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { sendEmail, downloadAttachedFile } from '../../../../store/contactUs/actions';
import { SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE, DOWNLOAD_ATTACHED_FILE_SUCCESS, DOWNLOAD_ATTACHED_FILE_FAILURE } from '../../../../store/contactUs/constants';

export default function ContactFooter(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ firstName: '', lastName: '', email: '', subject: '', message: '' });

  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));

  const handleSubmit = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (submitting) return;
      setSubmitting(true);

      const params = {
        ToEmail: formState.firstName + ' ' + formState.lastName,
        ToUsername: formState.email,
        // ToEmail: 'info@CipherDataSolutions.com',
        Subject: formState.subject,
        PlainTextContent: formState.message,
      };

      const message = `New message from website.
        First Name:` + formState.firstName + `
        Last Name: ` + formState.lastName  + `
        Email Address: ` + formState.email + `
        Subject: ` + formState.subject + `
        Message: ` + formState.message

      const email = {
        // ToEmail: formState.firstName + ' ' + formState.lastName,
        // ToUsername: formState.email,
        // Subject: formState.subject,
        // PlainTextContent: formState.message,
        
        FromEmail: 'info@CipherDataSolutions.com',
        FromUserName: 'Cipher Data Solutions',
        
        ToEmail: 'info@CipherDataSolutions.com',
        ToUsername: 'Cipher Data Solutions',
        
        Subject: 'New file download from website',
        PlainTextContent: 'Someone downloaded a file from the website!',
      };

      const user = {
        FirstName: formState.firstName,
        LastName: formState.lastName,
        EmailAddress: formState.email,
        CompanyName: formState.company,
        JobTitle: formState.jobTitle,
        SignupSource: 'Website',
      };

      const result = await dispatch(sendEmail(email));
      
      if (result.type === SEND_EMAIL_SUCCESS) {
        const resData = result.payload 
        //toast.success('Sent email successful');
        
        downloadFile();
        history.push(`/download-confirm`);
      }

      if (result.type === SEND_EMAIL_FAILURE) {
        const errorMessage =
          typeof result.errors === 'string' ? result.errors : 'Failed to send message';
        toast.error(errorMessage);
      }
      setSubmitting(false);
    } catch (err) {
      const errorMessage = typeof err.message === 'string' ? err.message : 'Could not send message';
      toast.error(errorMessage);
      //console.log(errorMessage);
      setSubmitting(false);
    }
  });

  const downloadFile = async () => {
    const result = await dispatch(downloadAttachedFile());

    if (result.type === DOWNLOAD_ATTACHED_FILE_SUCCESS) {
      const resData = result.payload 
      
      const fileName = "PurviewSetupScript";
      //const json = JSON.stringify(resData);
      const blob = new Blob([resData], {type:'application/zip'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (result.type === DOWNLOAD_ATTACHED_FILE_FAILURE) {
      const errorMessage =
        typeof result.errors === 'string' ? result.errors : 'Failed to download file';
      toast.error(errorMessage);
    }
  }

  function download(url, filename) {
    fetch(url, {
        mode: 'no-cors' /*{mode:'cors'}*/
    }).then((transfer) => {
        return transfer.blob();
    }).then((bytes) => {
        let elm = document.createElement('a');
        elm.href = URL.createObjectURL(bytes);
        elm.setAttribute('download', filename);
        elm.click()
    }).catch((error) => {
        console.log(error);
    })
  }
  return (
    <footer className="footer container">
      <section className="contact-us">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 mt-5 mx-auto">
            <div className="card join-card">
              <div className="join-card-wrapper">
                <div className="join-title-wrapper">
                  <div className="join-wrapper-tile">Contact Info</div>
                  <p className="home-section-description download-sub-title">Download your free script!</p>
                </div>
                <form className="join-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>First Name</label>
                        <input type="text" name="firstName" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" name="lastName" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="email" name="email" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Company</label>
                        <input type="text" name="company" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Job Title</label>
                        <input type="text" name="jobTitle" className="form-control join-form-input" onChange={handleInputChange} required/>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                      <div className="form-check">
                        <input type="checkbox" name="agreeState" className="form-check-input" id="agree-state" required/>
                        <label className="form-check-label join-form-label" htmlFor="agree-state">By checking, I agree to share my form responses.</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex justify-content-center mb-3">
                      <button type="submit" className="btn btn-red">Download</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

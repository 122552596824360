import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../store/modal/actions';

import MenuModal from './MenuModal';

// const useOutsideAlerter = (ref) => {
//   const dispatch = useDispatch();

//   const handleClickOutside = (e) => {
//     if (ref.current && !ref.current.contains(e.target)) {
//       dispatch(closeModal());
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   });
// };

const Modal = () => {
  const dispatch = useDispatch();
  // const containerRef = useRef(null);
  const modal = useSelector((state) => state.modal);
  // useOutsideAlerter(containerRef);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  });

  const renderModal = () => {
    switch (modal.currentModal) {
      case 'MENU':
        return <MenuModal openModal={modal.modalOpen} closeModal={handleCloseModal} />;
      default:
        return null;
    }
  };

  if (!modal.modalOpen) {
    return null;
  }

  return (
    <div className="modal-container">
      {renderModal()}
    </div>
  );
};

export default Modal;

import {
  OPEN_SIDE_NAV,
} from './constants';

const initialState = { data: [], loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDE_NAV:
      return { ...state, navigationData: action.payload };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';

export default function Privacy() {
  return (
    <div className="privacy-page main">
      <div className="privacy-wrapper">
        <h1 className="privacy-page-title">Third Party</h1>
        <div className="privacy-content-wrapper">
          <h3 className="privacy-sub-title">
            <a href="https://www.hangfire.io/licensing/third-party.html" className="privacy-sub-title-link">Hangfire</a> (LGPLv3)</h3>
          <p className="privacy-copyright">Copyright © 2013–2021 Sergey Odinokov. All rights reserved.</p>
          <div className="privacy-description">
            This program is free software: you can redistribute it and/or modify it under the terms of the GNU Lesser General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.
            This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Lesser General Public License for more details.
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  DOWNLOAD_ATTACHED_FILE_REQUEST,
  DOWNLOAD_ATTACHED_FILE_SUCCESS,
  DOWNLOAD_ATTACHED_FILE_FAILURE,
} from './constants';

const initialState = { data: [], loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return { ...state, loading: true };
    case SEND_EMAIL_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case SEND_EMAIL_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case DOWNLOAD_ATTACHED_FILE_REQUEST:
      return { ...state, loading: true };
    case DOWNLOAD_ATTACHED_FILE_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case DOWNLOAD_ATTACHED_FILE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;